import axios from 'axios';

// Create an instance of axios with default settings
const api = axios.create({
  baseURL: 'http://143.110.253.93:3000/api',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export default api;
