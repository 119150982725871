import React from 'react';
import { Link, useLocation } from "react-router-dom";

function Sidebar() {
    const location = useLocation();

    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                <li className="nav-item nav-profile">
                    <a href="#" className="nav-link">
                        <div className="nav-profile-image">
                            <img src="admin/assets/images/faces/face1.jpg" alt="profile" />
                            <span className="login-status online" />
                        </div>
                        <div className="nav-profile-text d-flex flex-column">
                            <span className="font-weight-bold mb-2">Admin</span>
                            <span className="text-secondary text-small">admin@admin.com</span>
                        </div>
                        <i className="mdi mdi-bookmark-check text-success nav-profile-badge" />
                    </a>
                </li>

                {/* Dashboard */}
                <li className={`nav-item ${location.pathname === '/adminDashboard' ? 'active' : ''}`}>
                    <Link className="nav-link" to={"/adminDashboard"}>
                        <span className="menu-title">Dashboard</span>
                        <i className="mdi mdi-view-dashboard menu-icon" />
                    </Link>
                </li>

                {/* Property Type */}
                <li className={`nav-item ${location.pathname === '/admin-propertytype-index' ? 'active' : ''}`}>
                    <Link className="nav-link" to={"/admin-propertytype-index"}>
                        <span className="menu-title">Property Type</span>
                        <i className="mdi mdi-office-building menu-icon" />
                    </Link>
                </li>

                {/* Properties */}
                <li className={`nav-item ${location.pathname === '/admin-property-index' ? 'active' : ''}`}>
                    <Link className="nav-link" to={"/admin-property-index"}>
                        <span className="menu-title">Properties</span>
                        <i className="mdi mdi-home-city-outline menu-icon" />
                    </Link>
                </li>

                {/* Customer's Property */}
                <li className={`nav-item ${location.pathname === '/admin-customerproperty-index' ? 'active' : ''}`}>
                    <Link className="nav-link" to={"/admin-customerproperty-index"}>
                        <span className="menu-title">Customer's Property</span>
                        <i className="mdi mdi-domain menu-icon" />
                    </Link>
                </li>

                {/* Payment */}
                <li className={`nav-item ${location.pathname === '/admin-payment-verify-index' ? 'active' : ''}`}>
                    <Link className="nav-link" to={"/admin-payment-verify-index"}>
                        <span className="menu-title">Payment</span>
                        <i className="mdi mdi-cash-multiple menu-icon" />
                    </Link>
                </li>

                {/* Customers */}
                <li className={`nav-item ${location.pathname === '/customer-index' ? 'active' : ''}`}>
                    <Link className="nav-link" to={"/customer-index"}>
                        <span className="menu-title">Customers</span>
                        <i className="mdi mdi-account-multiple menu-icon" />                     
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

export default Sidebar;
