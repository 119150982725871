import React, { useEffect, useState } from 'react'
import Navbar from '../Layouts/Navbar'
import Sidebar from '../Layouts/Sidebar'
import Footer from '../Layouts/Footer'
import { getUser } from '../../Services/Constants';
import api from '../../Services/Api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
const stateOptions = [
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" }
];

const cityOptions = [
  { label: "Ahmedabad", value: "Ahmedabad" },
  { label: "Amreli", value: "Amreli" },
  { label: "Anand", value: "Anand" },
  { label: "Anjar", value: "Anjar" },
  { label: "Babra", value: "Babra" },
  { label: "Bharuch", value: "Bharuch" },
  { label: "Bhavnagar", value: "Bhavnagar" },
  { label: "Bhuj", value: "Bhuj" },
  { label: "Borsad", value: "Borsad" },
  { label: "Botad", value: "Botad" },
  { label: "Chhota Udepur", value: "Chhota Udepur" },
  { label: "Dahod", value: "Dahod" },
  { label: "Dhoraji", value: "Dhoraji" },
  { label: "Gandhinagar", value: "Gandhinagar" },
  { label: "Godhra", value: "Godhra" },
  { label: "Gondal", value: "Gondal" },
  { label: "Himatnagar", value: "Himatnagar" },
  { label: "Idar", value: "Idar" },
  { label: "Jamnagar", value: "Jamnagar" },
  { label: "Jetpur", value: "Jetpur" },
  { label: "Junagadh", value: "Junagadh" },
  { label: "Kadi", value: "Kadi" },
  { label: "Kalol", value: "Kalol" },
  { label: "Mahuva", value: "Mahuva" },
  { label: "Mandvi", value: "Mandvi" },
  { label: "Mansa", value: "Mansa" },
  { label: "Mehsana", value: "Mehsana" },
  { label: "Modasa", value: "Modasa" },
  { label: "Morbi", value: "Morbi" },
  { label: "Mundra", value: "Mundra" },
  { label: "Nadiad", value: "Nadiad" },
  { label: "Navsari", value: "Navsari" },
  { label: "Palanpur", value: "Palanpur" },
  { label: "Patan", value: "Patan" },
  { label: "Porbandar", value: "Porbandar" },
  { label: "Rajkot", value: "Rajkot" },
  { label: "Radhanpur", value: "Radhanpur" },
  { label: "Sanand", value: "Sanand" },
  { label: "Sidhpur", value: "Sidhpur" },
  { label: "Surat", value: "Surat" },
  { label: "Surendranagar", value: "Surendranagar" },
  { label: "Unjha", value: "Unjha" },
  { label: "Valsad", value: "Valsad" },
  { label: "Vapi", value: "Vapi" },
  { label: "Veraval", value: "Veraval" },
  { label: "Viramgam", value: "Viramgam" },
  { label: "Visavadar", value: "Visavadar" },
  { label: "Visnagar", value: "Visnagar" },
  { label: "Vyara", value: "Vyara" }
];

const countryOptions = [
  { label: "India", value: "India" },
];

function CustomerIndex() {

  const user = JSON.parse(getUser());
  const token = user.token;
  const [loading, setLoading] = useState(false); // Loading state
  const [page, setPage] = useState(1); // Default to page 1
  const [limit, setLimit] = useState(10); // Default to limit 2
  const [customers, SetCustomers] = useState([]);
  const [errors, setErrors] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCustomer, setSearchCustomer] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [editId, setEditId] = useState(null);
  const [state, setState] = useState({
    errors: [],
    form: {
      fname: '',
      lname: '',
      email: '',
      contact: '',
      contact2: '',
      address: '',
      country: '',
      state: '',
      city: '',
      pincode: '',
    },
  });

  useEffect(() => {
    list(); // Fetch data on component mount
    fetchCustomers();
  }, [page, limit, searchQuery, searchCustomer]);



  const fetchCustomers = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await api.get(`/admin/customer/list`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
        },
      });
      if (response.data.status) {
        setCustomerList(response.data.data);
      } else {
        setErrors(response.data.errors || response.data.message);
      }
    } catch (error) {
      setErrors([{ msg: 'Failed to fetch property types, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };


  const list = async () => {
    if (loading) return; // prevent double execution
    try {
      setLoading(true);
      const response = await api.get(`/admin/customer/list?page=${page}&limit=${limit}&searchName=${searchQuery}&searchEmail=${searchCustomer}`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json'
        }
      });
      if (response.data.status === true) {
        SetCustomers(response.data.data);
        setTotalPages(response.data.pagination.totalPages);
        // toast.success(response.data.message);
      } else {
        toast.error(response.data.message || response.data.errors[0]);
        setErrors(response.data.errors || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrors(error.response?.data?.errors || [{ msg: 'Failed to fetch data, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };


  // const handleCreateUpdate = async (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("fname", state.form.fname);
  //   formData.append("lname", state.form.lname);
  //   formData.append("email", state.form.email);
  //   formData.append("contact", state.form.contact);
  //   formData.append("contact2", state.form.contact2);
  //   formData.append("address", state.form.address);
  //   formData.append("country", state.form.country);
  //   formData.append("state", state.form.state);
  //   formData.append("city", state.form.city);
  //   formData.append("pincode", state.form.pincode);

  //   // If editing, append the property ID
  //   if (editId) {
  //     formData.append("id", editId);
  //   }

  //   for (let [key, value] of formData.entries()) {
  //     console.log(`${key}: ${value}`);
  //   }
  //   try {
  //     const response = await api.post("/admin/customer/create/update", formData, {
  //       headers: {
  //         'Authorization': `${token}`,
  //         'Accept': 'application/json',
  //         "Content-Type": "x-www-form-urlencoded",
  //       },
  //     });

  //     Swal.fire({
  //       icon: "success",
  //       title: "Success",
  //       text: response.data.message,
  //     });

  //     // Reset form
  //     setState({
  //       ...state,
  //       form: {
  //         fname: '',
  //         lname: '',
  //         email: '',
  //         contact: '',
  //         contact2: '',
  //         address: '',
  //         country: '',
  //         state: '',
  //         city: '',
  //         pincode: '',
  //       },
  //       errors: [],
  //     });
  //     setEditId(null);

  //     // Fetch updated properties
  //     fetchCustomers();

  //     // Close modal
  //     const modalElement = document.getElementById("staticBackdrop");
  //     const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
  //     modalInstance.hide();

  //   } catch (error) {
  //     console.error(error);
  //     setState((prevState) => ({
  //       ...prevState,
  //       errors: error.response.data.errors || [{ msg: "Failed to create/update data, please try again." }],
  //     }));
  //   }
  // };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      form: { ...prevState.form, [name]: value },
    }));
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };



  const handleCreateUpdate = async (e) => {
    e.preventDefault();

    // Construct payload as JSON
    const customerData = {
      fname: state.form.fname,
      lname: state.form.lname,
      email: state.form.email,
      contact: state.form.contact,
      contact2: state.form.contact2,
      address: state.form.address,
      country: state.form.country,
      state: state.form.state,
      city: state.form.city,
      pincode: state.form.pincode,
    };

    // If updating, include the id explicitly
    if (editId) {
      customerData.id = editId;
    }

    try {
      // Log payload to confirm data being sent
      console.log("Payload being sent:", customerData);

      const response = await api.post("/admin/customer/create/update", customerData, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      // Log the response to ensure it's successful
      console.log("Response:", response.data);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });

      // Reset form and errors
      setState({
        ...state,
        form: {
          fname: '',
          lname: '',
          email: '',
          contact: '',
          contact2: '',
          address: '',
          country: '',
          state: '',
          city: '',
          pincode: '',
        },
        errors: [],
      });

      setEditId(null);
      // Fetch updated customers
      fetchCustomers();

      // Close modal
      const modalElement = document.getElementById("staticBackdrop");
      const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
      if (modalInstance) modalInstance.hide();

    } catch (error) {
      console.error("Error:", error.response?.data);

      // Handle error response
      const errorData = error.response?.data || { errors: [{ msg: "Failed to create/update data, please try again." }] };
      setState((prevState) => ({
        ...prevState,
        errors: errorData.errors,
      }));
    }
  };


  const handleEdit = (customer) => {
    // Convert existing images to a format that can be used in the files array

    setState((prevState) => ({
      ...prevState,
      form: {
        fname: customer?.fname,
        lname: customer?.lname,
        email: customer?.email,
        contact: customer?.contact,
        contact2: customer?.contact2,
        address: customer?.address,
        country: customer?.country,
        state: customer?.state,
        city: customer?.city,
        pincode: customer?.pincode,
      },
      errors: [],
    }));
    setEditId(customer._id); // Set the ID for editing
    const modalElement = document.getElementById("staticBackdrop");
    const modalInstance = new window.bootstrap.Modal(modalElement);
    modalInstance.show(); // Open the modal
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const response = await api.delete(`/admin/customer/delete`, {
            data: { id }, // Adjust to ensure id is passed correctly
            headers: {
              'Authorization': `${token}`,
              'Accept': 'application/json',
            },
          });

          if (response.data.status === true) {
            list();
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message || response.data.errors[0]?.msg);
          }
        } catch (error) {
          console.error('Error deleting property type:', error);
          toast.error('Failed to delete data, please try again.');
        } finally {
          setLoading(false);
        }
      } else if (result.isDenied) {
        Swal.fire('Deletion cancelled', '', 'info');
      }
    });
  };

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <button
              type="button"
              className="btn btn-primary mb-5"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  form: {
                    fname: '',
                    lname: '',
                    email: '',
                    contact: '',
                    contact2: '',
                    address: '',
                    country: '',
                    state: '',
                    city: '',
                    pincode: '',
                    city: '',
                  },
                  errors: [], // Clear previous errors
                }));
                setEditId(null);
              }}
            >
              Create/Update Property
            </button>
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleCreateUpdate}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">
                        Create/Update Property
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>

                    <div className="modal-body">
                      {/* Form Fields */}
                      {['fname', 'lname', 'email', 'contact', 'contact2', 'address', 'pincode']?.map(field => (
                        <div className="form-group" key={field}>
                          <label htmlFor={field}>{field.replace('_', ' ')}</label>
                          <input
                            type="text"
                            className="form-control"
                            id={field}
                            name={field}
                            placeholder={`Enter ${field.replace('_', ' ')}`}
                            value={state.form[field]}
                            onChange={handleInputChange}
                          />
                          {state.errors && state.errors.filter((error) => error.path === field).slice(0, 1).map((error, index) => (
                            <div key={index} className="text-danger">
                              {error.msg}
                            </div>
                          ))}
                        </div>
                      ))}
                      <div className='pt-3'>
                        <select
                          name="state"
                          value={state.form.state}
                          onChange={handleInputChange}
                          className="form-select"
                        >
                          <option value="">Select State</option>
                          {stateOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {state.errors && state.errors.filter((error) => error.path === 'state').slice(0, 1).map((error, index) => (
                          <div key={index} className="text-danger">
                            {error.msg}
                          </div>
                        ))}
                      </div>
                      <div className='pt-3'>
                        <select
                          name="city"
                          value={state.form.city}
                          onChange={handleInputChange}
                          className="form-select"
                        >
                          <option value="">Select City</option>
                          {cityOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {state.errors && state.errors.filter((error) => error.path === 'city').slice(0, 1).map((error, index) => (
                          <div key={index} className="text-danger">
                            {error.msg}
                          </div>
                        ))}
                      </div>
                      <div className='pt-3'>
                        <select
                          name="country"
                          value={state.form.country}
                          onChange={handleInputChange}
                          className="form-select"
                        >
                          <option value="">Select Country</option>
                          {countryOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {state.errors && state.errors.filter((error) => error.path === 'country').slice(0, 1).map((error, index) => (
                          <div key={index} className="text-danger">
                            {error.msg}
                          </div>
                        ))}
                      </div>
                    </div>


                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary" disabled={state.loading}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              {/* Table Section */}
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Customer's list</h4>
                    <p className="card-description">List of Customer's list</p>

                    <div className='row'>
                      <div className='col-md-4'>
                        <input
                          type="text"
                          placeholder="Search Customer's name"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          className="form-control mb-3"
                        />
                      </div>
                      <div className='col-md-4'>
                        <div className="form-group">
                          {/* <label htmlFor="customer">Customer </label> */}
                          <select
                            // value={searchCustomer}
                            className="form-control"
                            id="searchCustomer"
                            name="searchCustomer"
                            onChange={(e) => { setSearchCustomer(e.target.value) }}
                          >
                            <option value="">Select Customer</option>
                            {customerList.map((customer) => (
                              <option key={customer._id} value={customer.email}>
                                {customer.email}
                              </option>
                            ))}
                          </select>
                          {errors && errors.filter((error) => error.path === "customer_id").slice(0, 1).map((error, index) => (
                            <div key={index} className="text-danger">
                              {error.msg}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {loading ? (
                      <p>Loading...</p>
                    )
                      :
                      (
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Customer's Name</th>
                              <th>Customer's Surname</th>
                              <th>Contact</th>
                              <th>Email</th>
                              <th>Address</th>
                              <th>Country</th>
                              <th>State</th>
                              <th>City</th>
                              <th>Actions</th>

                            </tr>
                          </thead>
                          <tbody>
                            {customers.length > 0 ? (
                              customers.map((customer) => (
                                <tr key={customer?._id}>
                                  <td>{customer?.fname}</td>
                                  <td>{customer?.lname}</td>
                                  <td>{customer?.contact}</td>
                                  <td>{customer?.email}</td>
                                  <td>{customer?.address}</td>
                                  <td>{customer?.country}</td>
                                  <td>{customer?.state}</td>
                                  <td>{customer?.city}</td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-warning me-2"
                                      onClick={() => handleEdit(customer)}
                                    >
                                      <BsPencilSquare />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-danger me-2"
                                      onClick={() => handleDelete(customer?._id)}
                                    >
                                      <BsTrash />
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9" className="text-center">No property types found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <button className="btn btn-primary" onClick={handlePreviousPage} disabled={page === 1}>
                      Previous
                    </button>
                    <span>Page {page} of {totalPages}</span>
                    <button className="btn btn-primary" onClick={handleNextPage} disabled={page === totalPages}>
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )


}

export default CustomerIndex
