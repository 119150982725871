import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Processing from '../Services/Processing';
import api from '../Services/Api';
import constants, { getUser, saveUserLocally } from '../Services/Constants'
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';


function VerifyOtp() {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // const user = JSON.parse(getUser());
        const user =  JSON.parse(localStorage.getItem('verify'));
        setEmail(user.data);
    }, [email]);
    
    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await api.post('/admin/verify/mail/otp', new URLSearchParams({
                email,
                otp, // Send OTP along with email
            }));
            console.log('Verification successful:', response);
            setLoading(false);
            if (response.data.status === true) {
                toast.success("OTP verification successful!");
                saveUserLocally(JSON.stringify(response?.data));
                navigate('/adminDashboard'); // Redirect on success
            } else {
                console.log(response,'in false--------');
                toast.error(response.data.message || "Verification failed");
            }
        } catch (error) {
            setLoading(false);
            console.log('Verification error:', error);
            setErrors(error.response.data.errors);
            toast.error(error.response.data.message || "Verification failed");

        }
    };


    return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <meta name="description" content="Web site created using create-react-app" />
            {/* <link rel="shortcut icon" href="assets/web/logos/fevicone_rentable.png" type="image/x-icon"> */}
            <link rel="shortcut icon" href="assets/web/logos/fevicone_rentable.png" type="image/x-icon" />
            <link rel="stylesheet" href="assets/web/css/bootstrap.css" />
            <link rel="stylesheet" href="assets/web/css/all.min.css" />
            <link rel="stylesheet" href="assets/web/css/login.css" />
            <title>Rentale Verify Page</title>
      </Helmet>
        <section className="login-section">
            {loading ? <div className="card loginCard"><Processing /></div>
                :
                (<div className="card loginCard">
                    <div className="card-body">
                        <div className="page-content text-center">
                            <img src="assets/web/logos/rentable_main.png" alt="Main Logo" height="60" width="200"/>
                            <h2 className="pageTitle">Welcome to</h2>
                            <p className="pagePera">Verify your account.!</p>
                        </div>
                        <form onSubmit={handleVerifyOtp}>
                            <div className="form-outline form-white mb-4">
                                <div className="form-outline form-white mb-3">
                                    <input
                                        type="number"
                                        name="otp"
                                        id="otp"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter OTP"
                                    />
                                    {errors && errors.filter(error => error.path === 'otp').slice(0, 1).map((error, index) => (
                                        <div key={index} className="text-danger">
                                            {error.msg}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="remember text-center d-flex justify-content-between flex-wrap gap-3 mt-4">
                                Having trouble in Login?
                            </div>
                            <button className="btn loginButton" type="submit">
                                Login
                            </button>

                        </form>
                    </div>
                </div>)}
        </section>
        </>
    )
}

export default VerifyOtp
