import React, { useEffect, useState } from 'react'
import Navbar from '../Layouts/Navbar'
import Sidebar from '../Layouts/Sidebar'
import Footer from '../Layouts/Footer'
import { getUser } from '../../Services/Constants';
import api from '../../Services/Api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

function PaymentIndex() {

  const user = JSON.parse(getUser());
  const token = user.token;

  const [loading, setLoading] = useState(false); // Loading state
  const [page, setPage] = useState(1); // Default to page 1
  const [limit, setLimit] = useState(10); // Default to limit 2
  const [payments, setPayments] = useState([]);
  const [errors, setErrors] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [searchEmail, setSearchEmail] = useState('');
  const [customers, setCustomers] = useState([]);
  const [paymentDate, setPaymentDate] = useState([]);
  const [rentDate, setRentDate] = useState([]);
  const [propertyName, setPropertyName] = useState('');


  useEffect(() => {
    list(); // Fetch data on component mount
    fetchCustomers();
  }, [page, limit, searchEmail, paymentDate, rentDate, propertyName]);


  const fetchCustomers = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await api.get(`/admin/customer/list`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
        },
      });
      if (response.data.status) {
        setCustomers(response.data.data);
      } else {
        setErrors(response.data.errors || response.data.message);
      }
    } catch (error) {
      setErrors([{ msg: 'Failed to fetch property types, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };


  const list = async () => {
    if (loading) return; // prevent double execution
    try {
      setLoading(true);
      const response = await api.get(`/admin/verify/rent/list?page=${page}&limit=${limit}&email=${searchEmail}&paymentDate=${paymentDate}&rentDate=${rentDate}&propertyName=${propertyName}`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json'
        }
      });
      if (response.data.status === true) {
        setPayments(response.data.data);
        setTotalPages(response.data.pagination.totalPages);
        // toast.success(response.data.message);
      } else {
        toast.error(response.data.message || response.data.errors[0]);
        setErrors(response.data.errors || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrors(error.response?.data?.errors || [{ msg: 'Failed to fetch data, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };


  const handleVerify = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, verify it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          // The correct structure of axios.post:
          const response = await api.post(
            `/admin/verify/rent/payment`,
            { id, verify: 1 }, // This is the request payload (data)
            {
              headers: {
                'Authorization': `${token}`,
                'Accept': 'application/json',
              },
            }
          );

          if (response.data.status === true) {
            list();
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message || response.data.errors[0]?.msg);
          }
        } catch (error) {
          console.error('Error changing status:', error);
          toast.error('Failed to change status, please try again.');
        } finally {
          setLoading(false);
        }
      } else if (result.isDenied) {
        Swal.fire('Status change cancelled', '', 'info');
      }
    });
  };


  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="d-flex justify-content-between">
              {/* Table Section */}
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Customer's Payments</h4>
                    <p className="card-description">List of Customer's Payments</p>

                    <div className='row'>
                      <div className='col-md-3'>
                        <label className='text-center p-2'>Payment Date</label>
                        <input
                          type="date"
                          placeholder="Search by Payment date"
                          value={paymentDate}
                          onChange={(e) => { setPaymentDate(e.target.value) }}
                          className="form-control mb-3"
                        />
                      </div>
                      <div className='col-md-3'>
                        <label className='text-center p-2'>Rent Date</label>
                        <input
                          type="date"
                          placeholder="Search by Rent date"
                          value={rentDate}
                          onChange={(e) => { setRentDate(e.target.value) }}
                          className="form-control mb-3"
                        />
                      </div>
                      <div className='col-md-3'>
                      <label className='text-center p-2'>Property Name</label>
                        <input
                          type="text"
                          placeholder="Search Property Name"
                          value={propertyName}
                          onChange={(e)=>{setPropertyName(e.target.value)}}
                          className="form-control mb-3"
                        />
                      </div>
                      <div className='col-md-3'>
                        <div className="form-group">
                          <label className='text-center p-2'>Search by customers</label>
                          <select
                            // value={searchCustomer}
                            className="form-control"
                            id="searchEmail"
                            name="searchEmail"
                            onChange={(e) => { setSearchEmail(e.target.value) }}
                          >
                            <option value="">Select Customer</option>
                            {customers.map((customer) => (
                              <option key={customer._id} value={customer.email}>
                                {customer.email}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                    </div>

                    {loading ? (
                      <p>Loading...</p>
                    )
                      :
                      (
                        <div className='table-responsive'>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Customer Name</th>
                                <th>Customer Contact</th>
                                <th>Customer Email</th>
                                <th>Property Name</th>
                                <th>Squarefeet</th>
                                <th>Address</th>
                                <th>Rent Amount</th>
                                <th>Paid Rent</th>
                                <th>Payment Date</th>
                                <th>Rent Date</th>
                                <th>Payment Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {payments.length > 0 ? (
                                payments.map((payment) => (
                                  <tr key={payment?._id}>
                                    <td>{payment?.customer_property_id?.customer_id?.fname}</td>
                                    <td>{payment?.customer_property_id?.customer_id?.contact}</td>
                                    <td>{payment?.customer_property_id?.customer_id?.email}</td>
                                    <td>{payment?.customer_property_id?.property_id?.name}</td>
                                    <td>{payment?.customer_property_id?.property_id?.squarefeet}</td>
                                    <td>{payment?.customer_property_id?.property_id?.address.length > 5 ? payment?.customer_property_id?.property_id?.address.slice(0, 10) + '...' : payment?.customer_property_id?.property_id?.address}</td>
                                    <td>{payment?.customer_property_id?.property_id?.rent_amount}</td>
                                    <td>{payment?.amount}</td>
                                    <td>{new Date(payment.payment_date).toLocaleDateString()}</td>
                                    <td>{new Date(payment.rent_date).toLocaleDateString()}</td>
                                    <td> <button
                                      className={`btn btn-${payment?.verified == true ? 'success disabled' : 'danger'} btn-sm m-2`}
                                      onClick={() => handleVerify(payment?._id)}
                                    >
                                      {/* <BsPencilSquare /> */} {payment?.verified == true ? 'Verify' : 'Pending'}
                                    </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="11" className="text-center">No property types found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <button className="btn btn-primary" onClick={handlePreviousPage} disabled={page === 1}>
                      Previous
                    </button>
                    <span>Page {page} of {totalPages}</span>
                    <button className="btn btn-primary" onClick={handleNextPage} disabled={page === totalPages}>
                      Next
                    </button>
                  </div>
                </div>
              </div>

              {/* Modal Button Section */}
              {/* <div className="col-lg-4"> */}

              {/* </div> */}

            </div>


          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default PaymentIndex
