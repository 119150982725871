import React, { useState, useEffect } from 'react';
import Navbar from '../Layouts/Navbar';
import Sidebar from '../Layouts/Sidebar';
import Footer from '../Layouts/Footer';
import { toast } from 'react-toastify';
import api from '../../Services/Api';
import { getUser } from '../../Services/Constants';
import { BsTrash, BsPencilSquare, BsEye, BsX } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function PropertyIndex() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    page: 1,
    limit: 10,
    properties: [],
    propertyTypes: [],
    loading: false,
    searchQuery: '', // Added search query
    errors: [],
    form: {
      name: '',
      text: '',
      address: '',
      squarefeet: '',
      rent_amount: '',
      property_type_id: '',
      files: [],
    },
  });

  const [editId, setEditId] = useState(null);
  const user = JSON.parse(getUser());
  const token = user.token;

  useEffect(() => {
    fetchProperties();
    fetchPropertyTypes();
  }, [state.page, state.limit, state.searchQuery]); // Update fetch on search query change

  const fetchProperties = async () => {
    if (state.loading) return;
    try {
      setState(prevState => ({ ...prevState, loading: true }));
      const response = await api.get(`/admin/property/list?page=${state.page}&limit=${state.limit}&search=${state.searchQuery}`, { // Added search param
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
        },
      });
      if (response.data.status) {
        setState(prevState => ({
          ...prevState,
          properties: response.data.data,
          pagination: response.data.pagination, // Store pagination data
          errors: [],
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          errors: response.data.errors || [{ msg: response.data.message }],
        }));
      }
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        errors: [{ msg: 'Failed to fetch data, please try again.' }],
      }));
    } finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const fetchPropertyTypes = async () => {
    if (state.loading) return;
    try {
      setState(prevState => ({ ...prevState, loading: true }));
      const response = await api.get(`/admin/property/type/list`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
        },
      });
      if (response.data.status) {
        setState(prevState => ({
          ...prevState,
          propertyTypes: response.data.data,
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          errors: response.data.errors || [{ msg: response.data.message }],
        }));
      }
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        errors: [{ msg: 'Failed to fetch property types, please try again.' }],
      }));
    } finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      form: { ...prevState.form, [name]: value },
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        files: [...prevState.form.files, ...files], // Append new files to the existing ones
      },
    }));
  };



  const handleCreateUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", state.form.name);
    formData.append("text", state.form.text);
    formData.append("address", state.form.address);
    formData.append("squarefeet", state.form.squarefeet);
    formData.append("rent_amount", state.form.rent_amount);
    formData.append("property_type_id", state.form.property_type_id);

    // Add both new files and existing files to formData
    state.form.files.forEach((file) => {
      if (file.existing) {
        formData.append("files[]", file.name); // Append existing file names
      } else {
        formData.append("files", file); // Append new files
      }
    });

    // If editing, append the property ID
    if (editId) {
      formData.append("id", editId);
    }

    try {
      const response = await api.post("admin/property/create/update", formData, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
          "Content-Type": "multipart/form-data",
        },
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });

      // Reset form
      setState({
        ...state,
        form: {
          name: '',
          text: '',
          address: '',
          squarefeet: '',
          rent_amount: '',
          property_type_id: '',
          files: [],
        },
        errors: [],
      });
      setEditId(null);

      // Fetch updated properties
      fetchProperties();

      // Close modal
      const modalElement = document.getElementById("staticBackdrop");
      const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
      modalInstance.hide();

    } catch (error) {
      console.error(error);
      setState((prevState) => ({
        ...prevState,
        errors: error.response.data.errors || [{ msg: "Failed to create/update data, please try again." }],
      }));
    }
  };



  const handleEdit = (property) => {
    // Convert existing images to a format that can be used in the files array
    const existingFiles = property.imagePaths.map((img) => ({
      id: img._id,
      name: img.name,
      url: img.image_path,
      existing: true,
    }));

    setState((prevState) => ({
      ...prevState,
      form: {
        name: property?.name,
        text: property?.text,
        address: property?.address,
        squarefeet: property?.squarefeet,
        rent_amount: property?.rent_amount,
        property_type_id: property?.property_type_id?._id,
        files: [...existingFiles], // Add existing files to the files array
      },
      errors: [],
    }));
    setEditId(property._id); // Set the ID for editing
    const modalElement = document.getElementById("staticBackdrop");
    const modalInstance = new window.bootstrap.Modal(modalElement);
    modalInstance.show(); // Open the modal
  };


  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await api.delete(`/admin/property/delete`, {
            data: { id },
            headers: {
              'Authorization': `${token}`,
              'Accept': 'application/json',
            },
          });

          if (response.data.status) {
            fetchProperties();
            Swal.fire('Deleted!', response.data.message, 'success');
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error(error.response.data.message);
          // toast.error('Failed to delete property.');
        }
      }
    });
  };

  const handleDeleteImage = async (id) => {
    try {
      const response = await api.delete(`/admin/property/image/delete`, {
        data: { id },
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
        },
      });
      if (response.data.status) {
        toast.success('Image deleted successfully!');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Failed to delete image.');
    }
  };

  const handleEditItemClickView = (id) => {
    navigate('/admin-property-index-view', { state: { id } })
  };



  const handleSearchChange = (e) => {
    setState(prevState => ({
      ...prevState,
      searchQuery: e.target.value, // Update search query
    }));
  };

  const handlePageChange = (page) => {
    setState(prevState => ({
      ...prevState,
      page, // Update page number
    }));
  };

  const handleLimitChange = (e) => {
    setState(prevState => ({
      ...prevState,
      limit: parseInt(e.target.value), // Update limit per page
    }));
  };



  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <button
              type="button"
              className="btn btn-primary mb-5"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  form: {
                    name: "",
                    text: "",
                    address: "",
                    squarefeet: "",
                    rent_amount: "",
                    property_type_id: "",
                    files: [],
                  },
                  errors: [], // Clear previous errors
                }));
                setEditId(null);
              }}
            >
              Create/Update Property
            </button>
            <div className="row mb-4">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search properties..."
                  value={state.searchQuery}
                  onChange={handleSearchChange} // Handle search input
                />
              </div>
            </div>


            {/* Modal for Create/Update */}
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleCreateUpdate}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">
                        Create/Update Property
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>

                    <div className="modal-body">
                      {/* Form Fields */}
                      {['name', 'text', 'address', 'squarefeet', 'rent_amount'].map(field => (
                        <div className="form-group" key={field}>
                          <label htmlFor={field}>{field.replace('_', ' ')}</label>
                          <input
                            type="text"
                            className="form-control"
                            id={field}
                            name={field}
                            placeholder={`Enter ${field.replace('_', ' ')}`}
                            value={state.form[field]}
                            onChange={handleInputChange}
                          />
                          {state.errors && state.errors.filter((error) => error.path === field).slice(0, 1).map((error, index) => (
                            <div key={index} className="text-danger">
                              {error.msg}
                            </div>
                          ))}
                        </div>
                      ))}

                      <div className="form-group">
                        <label htmlFor="property_type_id">Property Type</label>
                        <select
                          value={state.form.property_type_id}
                          className="form-control"
                          id="property_type_id"
                          name="property_type_id"
                          onChange={handleInputChange}
                        >
                          <option value="">Select property type</option>
                          {state.propertyTypes.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.type}
                            </option>
                          ))}
                        </select>
                        {state.errors && state.errors.filter((error) => error.path === "property_type_id").slice(0, 1).map((error, index) => (
                          <div key={index} className="text-danger">
                            {error.msg}
                          </div>
                        ))}
                      </div>

                      {/* Existing Images */}
                      {state.form.files.length > 0 && state.form.files.some(file => file.existing) && (
                        <div className="form-group">
                          <label>Existing Images</label>
                          <div className="d-flex flex-wrap">
                            {state.form.files.filter(file => file.existing).map((img, index) => (
                              <div key={index} className="position-relative m-2">
                                {/* Delete Button */}
                                <button
                                  type="button"
                                  className="btn  btn-primary position-absolute top-0 end-0"
                                  onClick={() => handleDeleteImage(img?.id)} // Call delete function
                                  style={{ borderRadius: '100%', padding: '2px 6px', fontSize: '18px', transform: 'translate(50%, -50%)' }}
                                >
                                  <BsX />
                                </button>

                                {/* Image */}
                                <img
                                  src={img.url} // Display existing image URL
                                  alt="Existing Property"
                                  className="img-fluid rounded img-thumbnail"
                                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      <div className="form-group">
                        <label htmlFor="files">Files</label>
                        <input
                          type="file"
                          className="form-control"
                          id="files"
                          name="files"
                          onChange={handleFileChange}
                          multiple
                        />
                        {state.errors && state.errors.filter((error) => error.path === "files").slice(0, 1).map((error, index) => (
                          <div key={index} className="text-danger">
                            {error.msg}
                          </div>
                        ))}
                      </div>
                    </div>


                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary" disabled={state.loading}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Property Types</h4>
                    <p className="card-description">List of property types</p>

                    {state.loading ? (
                      <p>Loading...</p>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Property's Images</th>
                              <th>Property Name</th>
                              <th>Text</th>
                              <th>Address</th>
                              <th>Square Feet</th>
                              <th>Rent Amount</th>
                              <th>Property Type</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state.properties.length > 0 ? (
                              state.properties.map((property, index) => (
                                <tr key={property?._id}>
                                  <td>{index + 1}</td>
                                  <td><img src={property?.imagePaths[0]?.image_path} alt="image" className="img-fluid rounded img-thumbnail w-75" style={{ height: '150px', objectFit: 'cover' }} /></td>
                                  <td>{property?.name}</td>
                                  <td>{property?.text?.length > 10 ? property?.text.slice(0, 10) + '...' : property?.text}</td>
                                  <td>{property?.address?.length > 10 ? property?.address.slice(0, 10) + '...' : property?.address}</td>
                                  <td>{property?.squarefeet}</td>
                                  <td>{property?.rent_amount}</td>
                                  <td>{property?.property_type_id?.type || 'N/A'}</td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-warning me-2"
                                      onClick={() => handleEdit(property)}
                                    >
                                      <BsPencilSquare />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-danger me-2"
                                      onClick={() => handleDelete(property?._id)}
                                    >
                                      <BsTrash />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-facebook"
                                      onClick={() => handleEditItemClickView(property?._id)}
                                    >
                                      <BsEye />
                                    </button>
                                  </td>
                                </tr>
                              )) 
                            ):
                              (<tr>
                                <td colSpan="9" className="text-center">No customer's property found</td>
                              </tr>)
                          }
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <button className="btn btn-primary"
                      disabled={state.page === 1}
                      onClick={() => handlePageChange(state.page - 1)}
                    >
                      Previous
                    </button>
                    <span>Page {state.page} of {state.pagination?.totalPages}</span>
                    <button className="btn btn-primary"
                      disabled={state.page === state.pagination?.totalPages}
                      onClick={() => handlePageChange(state.page + 1)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {state.errors.length > 0 && (
              <div className="alert alert-danger mt-3">
                <ul>
                  {state.errors.map((error, i) => (
                    <li key={i}>{error.msg}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );




}

export default PropertyIndex;
