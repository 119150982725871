import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <center>
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
            Copyright © {currentYear} <Link to={"/adminDashboard"} target="_blank" rel="noopener noreferrer">Rentable</Link>. All rights reserved.
          </span>
          </center>
        </div>
      </footer>

      <Helmet>
        <script>
          {`
            window.onload = function() {
              var script = document.createElement('script');
              script.src = 'admin/assets/js/dashboard.js';
              document.body.appendChild(script);
            };
          `}
        </script>
      </Helmet>
    </>
  );
}

export default Footer;
