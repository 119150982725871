import React, { useEffect, useState } from 'react';
import Navbar from './Layouts/Navbar';
import Sidebar from './Layouts/Sidebar';
import Footer from './Layouts/Footer';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import api from '../Services/Api';
import { getUser } from '../Services/Constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [barChartCustomer, setBarChartCustomer] = useState([]);
  const [barChartTotalRent, setBarChartTotalRent] = useState([]);
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [boxData, setBoxData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [totalActiveCustomers, setTotalActiveCustomers] = useState(0);
  const [totalActiveProperties, setTotalActiveProperties] = useState(0);
  const [totalActiveAllocatedProperties, setTotalActiveAllocatedProperties] = useState(0);

  const user = JSON.parse(getUser());
  const token = user.token;

  useEffect(() => {
    fetchBarData();
    fetchDoughnutData()
    fetchBoxData()
  }, []);


  const fetchBoxData = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await api.get(`/admin/box-data/list`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
        },
      });
      if (response) {
        const data = response?.data.data;
        // Assuming the response order is always consistent
        setTotalActiveCustomers(data[0]);
        setTotalActiveProperties(data[1]);
        setTotalActiveAllocatedProperties(data[2]);

      } else {
        setErrors(response.errors || response.message);
      }
    } catch (error) {
      setErrors([{ msg: 'Failed to fetch property types, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };

  const fetchBarData = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await api.get(`/admin/bar-chart/list`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
        },
      });
      if (response) {
        setBarChartCustomer(response.data.data.totalCustomers);
        setBarChartTotalRent(response.data.data.totalRent);
      } else {
        setErrors(response.errors || response.message);
      }
    } catch (error) {
      setErrors([{ msg: 'Failed to fetch property types, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };
  const fetchDoughnutData = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await api.get(`/admin/doughnut-chart/list`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
        },
      });
      if (response) {
        setData(response.data?.data?.data)
        setLabels(response.data?.data?.labels)
      } else {
        setErrors(response.errors || response.message);
      }
    } catch (error) {
      setErrors([{ msg: 'Failed to fetch property types, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };


  const visitSaleChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: 'Total Customers',
        data: barChartCustomer,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Total Rent',
        data: barChartTotalRent,
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const visitSaleChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 50000, // Adjust this step size to fit your data range dynamically
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Visit And Sales Statistics',
      },
    },
  };

  const trafficChartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: ['#ff6384', '#36a2eb', '#ffce56'],
        hoverBackgroundColor: ['#ff6384', '#36a2eb', '#ffce56'],
      },
    ],
  };

  const trafficChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header">
              <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2">
                  <i className="mdi mdi-home" />
                </span>{" "}
                Dashboard
              </h3>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">
                    <span />
                    Overview{" "}
                    <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle" />
                  </li>
                </ul>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-4 stretch-card grid-margin">
                <div className="card bg-gradient-danger card-img-holder text-white">
                  <div className="card-body">
                    <h4 className="font-weight-normal mb-3">
                      {totalActiveCustomers?.label}{" "}
                      <i className="mdi mdi-chart-line mdi-24px float-end" />
                    </h4>
                    <h2 className="mb-5">{totalActiveCustomers?.count}</h2>
                  </div>
                </div>
              </div>
              <div className="col-md-4 stretch-card grid-margin">
                <div className="card bg-gradient-info card-img-holder text-white">
                  <div className="card-body">
                    <h4 className="font-weight-normal mb-3">
                      {totalActiveProperties?.label}{" "}
                      <i className="mdi mdi-bookmark-outline mdi-24px float-end" />
                    </h4>
                    <h2 className="mb-5">{totalActiveProperties?.count}</h2>
                  </div>
                </div>
              </div>
              <div className="col-md-4 stretch-card grid-margin">
                <div className="card bg-gradient-success card-img-holder text-white">
                  <div className="card-body">
                    <h4 className="font-weight-normal mb-3">
                      {totalActiveAllocatedProperties?.label}{" "}
                      <i className="mdi mdi-diamond mdi-24px float-end" />
                    </h4>
                    <h2 className="mb-5">{totalActiveAllocatedProperties?.count}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Visit And Sales Statistics</h4>
                    <div style={{ position: 'relative', height: '400px' }}>
                      <Bar data={visitSaleChartData} options={visitSaleChartOptions} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Traffic Sources</h4>
                    <div style={{ position: 'relative', height: '400px' }}>
                      <Doughnut data={trafficChartData} options={trafficChartOptions} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
