import React from 'react'
import { Helmet } from 'react-helmet'
import constants from '../../Services/Constants';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';  

function Navbar() {
  const navigate = useNavigate(); 

  const logout = () => {
    localStorage.removeItem(constants.USER);
    toast.success("Successfully logged out.");
    navigate("/adminLogin");
    window.location.reload();
  };

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="admin/assets/vendors/mdi/css/materialdesignicons.min.css" />
        <link rel="stylesheet" href="admin/assets/vendors/ti-icons/css/themify-icons.css" />
        <link rel="stylesheet" href="admin/assets/vendors/css/vendor.bundle.base.css" />
        <link rel="stylesheet" href="admin/assets/vendors/font-awesome/css/font-awesome.min.css" />
        <link rel="stylesheet" href="admin/assets/vendors/bootstrap-datepicker/bootstrap-datepicker.min.css" />
        <link rel="stylesheet" href="admin/assets/css/style.css" />
      </Helmet>
      
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
          <a className="navbar-brand brand-logo" href="index.html"><img src="assets/web/logos/rentable_main.png" alt="logo" /></a>
          <a className="navbar-brand brand-logo-mini" href="index.html"><img src="assets/web/logos/rentable_main.png" alt="logo" /></a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
            <span className="mdi mdi-menu" />
          </button>
          <div className="search-field d-none d-md-block">
            <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <i className="input-group-text border-0 mdi mdi-magnify" />
                </div>
                <input type="text" className="form-control bg-transparent border-0" placeholder="Search projects" />
              </div>
            </form>
          </div>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile dropdown">
              <a className="nav-link dropdown-toggle" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                <div className="nav-profile-img">
                  <img src="admin/assets/images/faces/face1.jpg" alt="image" />
                  <span className="availability-status online" />
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">Admin</p>
                </div>
              </a>
              <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
                <div className="dropdown-divider" />
                <a className="dropdown-item" onClick={logout}>
                  <i  className="mdi mdi-logout me-2 text-primary" /> Logout 
                </a>
              </div>
            </li>
            <li className="nav-item d-none d-lg-block full-screen-link">
              <a className="nav-link">
                <i className="mdi mdi-fullscreen" id="fullscreen-button" />
              </a>
            </li>
           
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-bs-toggle="offcanvas">
            <span className="mdi mdi-menu" />
          </button>
        </div>
      </nav>
    </>
  )
}

export default Navbar
     