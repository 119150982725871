import React, { useState, useEffect } from 'react';
import Navbar from '../Layouts/Navbar';
import Sidebar from '../Layouts/Sidebar';
import Footer from '../Layouts/Footer';
import axios from 'axios';
import { toast } from 'react-toastify'; // Assuming you are using react-toastify for notifications
import api from '../../Services/Api';
import { getUser } from '../../Services/Constants';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import Swal from 'sweetalert2';

function CustomerPropertyIndex() {
  const [page, setPage] = useState(1); // Default to page 1
  const [limit, setLimit] = useState(10); // Default to limit 2
  const [customerProperty, setCustomerProperty] = useState([]); // To store the list of property types
  const [loading, setLoading] = useState(false); // Loading state
  const [errors, setErrors] = useState([]); // Error state
  const [properties, setProperties] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customer_id, setCustomer_id] = useState();
  const [property_id, setProperty_id] = useState();
  const [editId, setEditId] = useState(null); // For tracking the ID during edit
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCustomer, setSearchCustomer] = useState([]);


  useEffect(() => {
    list(); // Fetch data on component mount
    fetchProperties();
    fetchCustomers();
  }, [page, limit, searchQuery, searchCustomer]); // Refetch when page or limit changes

  const user = JSON.parse(getUser());
  const token = user.token;

  const list = async () => {
    if (loading) return; // prevent double execution
    try {
      setLoading(true);
      const response = await api.get(`/admin/customer/property/list?page=${page}&limit=${limit}&searchProperty=${searchQuery}&searchCustomer=${searchCustomer}`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json'
        }
      });
      if (response.data.status === true) {
        setCustomerProperty(response.data.data);
        setTotalPages(response.data.pagination.totalPages);
        // toast.success(response.data.message);
      } else {
        toast.error(response.data.message || response.data.errors[0]);
        setErrors(response.data.errors || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrors(error.response?.data?.errors || [{ msg: 'Failed to fetch data, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };


  const fetchProperties = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await api.get(`/admin/property/list`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
        },
      });
      if (response.data.status) {
        setProperties(response.data.data);
      } else {
        setErrors(response.data.errors || response.data.message);
      }
    } catch (error) {
      setErrors([{ msg: 'Failed to fetch property types, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };


  const fetchCustomers = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await api.get(`/admin/customer/list`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
        },
      });
      if (response.data.status) {
        setCustomers(response.data.data);
      } else {
        setErrors(response.data.errors || response.data.message);
      }
    } catch (error) {
      setErrors([{ msg: 'Failed to fetch property types, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };



  const handleCreateUpdate = async (e) => {
    e.preventDefault();

    const payload = {
      customer_id,
      property_id
    };

    // If editing, add the edit ID
    if (editId) {
      payload.id = editId;
    }

    try {
      const response = await api.post("admin/customer/property/create/update", payload, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      });      
      if (response.data.status == true) {
        Swal.fire({ icon: "success", title: "Success", text: response.data.message, });
      } else {
        toast.error(response.data.message);
      }
      list();
      setCustomer_id('');
      setProperty_id('');
      setEditId(null);
      fetchProperties();
      fetchCustomers();
      const modalElement = document.getElementById("staticBackdrop");
      const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
      modalInstance.hide();
    } catch (error) {
      console.error(error);
      setErrors(error.response?.data?.errors || [{ msg: "Failed to create/update data, please try again." }]);
      toast.error(error.response.data.message);
    }
  };


  const handleEdit = (property) => {
    setCustomer_id(property.customer_id?._id)
    setProperty_id(property.property_id?._id);
    setEditId(property._id);
    const modalElement = document.getElementById("staticBackdrop");
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    modalInstance.show(); // Open the modal
  };

  const handleStatus = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          // The correct structure of axios.post:
          const response = await api.post(
            `/admin/customer/property/status`,
            { id }, // This is the request payload (data)
            {
              headers: {
                'Authorization': `${token}`,
                'Accept': 'application/json',
              },
            }
          );

          if (response.data.status === true) {
            list();
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message || response.data.errors[0]?.msg);
          }
        } catch (error) {
          console.error('Error changing status:', error);
          toast.error('Failed to change status, please try again.');
        } finally {
          setLoading(false);
        }
      } else if (result.isDenied) {
        Swal.fire('Status change cancelled', '', 'info');
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };


  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">

            <button type="button" className="btn btn-primary mb-5" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => { setCustomer_id(''); setProperty_id(''); setEditId(null); }}>
              Create/Update Customer's Property
            </button>
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleCreateUpdate}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                      {/* Add modal content here */}

                      <div className="form-group">
                        <label htmlFor="property">Property</label>
                        <select
                          value={property_id}
                          className="form-control"
                          id="property_id"
                          name="property_id"
                          onChange={(e) => setProperty_id(e.target.value)}
                        >
                          <option value="">Select property</option>
                          {properties.map((property) => (
                            <option key={property._id} value={property._id}>
                              {property.name}  ₹{property.rent_amount}
                            </option>
                          ))}
                        </select>
                        {errors && errors.filter((error) => error.path === "property_id").slice(0, 1).map((error, index) => (
                          <div key={index} className="text-danger">
                            {error.msg}
                          </div>
                        ))}
                      </div>


                      <div className="form-group">
                        <label htmlFor="customer">Customer </label>
                        <select
                          value={customer_id}
                          className="form-control"
                          id="customer_id"
                          name="customer_id"
                          onChange={(e) => { setCustomer_id(e.target.value) }}
                        >
                          <option value="">Select Customer</option>
                          {customers.map((customer) => (
                            <option key={customer._id} value={customer._id}>
                              {customer.email}
                            </option>
                          ))}
                        </select>
                        {errors && errors.filter((error) => error.path === "customer_id").slice(0, 1).map((error, index) => (
                          <div key={index} className="text-danger">
                            {error.msg}
                          </div>
                        ))}
                      </div>

                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              {/* Table Section */}

              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Customer Property</h4>
                    <p className="card-description">List of Customer Property</p>

                    <div className='row'>
                      <div className='col-md-4'>
                        <input
                          type="text"
                          placeholder="Search Customer Property"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          className="form-control mb-3"
                        />
                      </div>
                      <div className='col-md-4'>
                        <div className="form-group">
                          {/* <label htmlFor="customer">Customer </label> */}
                          <select
                            // value={searchCustomer}
                            className="form-control"
                            id="searchCustomer"
                            name="searchCustomer"
                            onChange={(e) => { setSearchCustomer(e.target.value) }}
                          >
                            <option value="">Select Customer</option>
                            {customers.map((customer) => (
                              <option key={customer._id} value={customer.email}>
                                {customer.email}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {loading ? (
                      <p>Loading...</p>
                    )
                      :
                      (
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Customer Name</th>
                              <th>Customer Contact</th>
                              <th>Customer Email</th>
                              <th>Property Name</th>
                              <th>Squarefeet</th>
                              <th>Rent Amount</th>
                              <th>Address</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {customerProperty.length > 0 ? (
                              customerProperty.map((property) => (
                                <tr key={property?._id}>
                                  <td>{property?.customer_id?.fname}</td>
                                  <td>{property?.customer_id?.contact}</td>
                                  <td>{property?.customer_id?.email}</td>
                                  <td>{property?.property_id?.name}</td>
                                  <td>{property?.property_id?.squarefeet}</td>
                                  <td>{property?.property_id?.rent_amount}</td>
                                  {/* <td>{property?.property_id?.address}</td> */}
                                  <td>{property?.property_id?.address?.length > 10 ? property?.property_id?.address.slice(0, 10) + '...' : property?.property_id?.address}</td>
                                  <td> <button
                                    className={`btn btn-${property?.status == true ? 'success' : 'danger'} btn-sm m-2`}
                                    onClick={() => handleStatus(property?._id)}
                                  >
                                    {/* <BsPencilSquare /> */} {property?.status == true ? 'Active' : 'Deactive'}
                                  </button>
                                  </td>
                                  <td>
                                    <button
                                      className={`btn btn-warning btn-sm m-2 ${property?.status == true ? '' : 'disabled'}`}
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdrop"
                                      onClick={() => handleEdit(property)}
                                    >
                                      <BsPencilSquare />
                                    </button>

                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9" className="text-center">No customer's property found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <button className="btn btn-primary" onClick={handlePreviousPage} disabled={page === 1}>
                      Previous
                    </button>
                    <span>Page {page} of {totalPages}</span>
                    <button className="btn btn-primary" onClick={handleNextPage} disabled={page === totalPages}>
                      Next
                    </button>
                  </div>
                </div>
              </div>

              {/* Modal Button Section */}
              {/* <div className="col-lg-4"> */}

              {/* </div> */}

            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>

  );


}

export default CustomerPropertyIndex;
