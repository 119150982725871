import React, { useState, useEffect } from 'react';
import Navbar from '../Layouts/Navbar';
import Sidebar from '../Layouts/Sidebar';
import Footer from '../Layouts/Footer';
import axios from 'axios';
import { toast } from 'react-toastify';
import api from '../../Services/Api';
import { getUser } from '../../Services/Constants';
import { BsTrash, BsPencilSquare } from 'react-icons/bs';
import Swal from 'sweetalert2';


function PropertyTypeIndex() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [type, setType] = useState('');
  // const [modalVisible, setModalVisible] = useState(false);
  const [editId, setEditId] = useState(null); // For tracking the ID during edit
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    list();
  }, [page, limit, searchQuery]);

  const user = JSON.parse(getUser());
  const token = user.token;

  const list = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await api.get(`/admin/property/type/list?page=${page}&limit=${limit}&search=${searchQuery}`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json'
        }
      });
      if (response.data.status === true) {
        setPropertyTypes(response.data.data);
        setTotalPages(response.data.pagination.totalPages);
        // toast.success(response.data.message);
      } else {
        toast.error(response.data.message || response.data.errors[0]?.msg);
        setErrors(response.data.errors || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrors(error.response?.data?.errors || [{ msg: 'Failed to fetch data, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };

  const modalElement = document.getElementById('staticBackdrop');
  const modalInstance = bootstrap.Modal.getInstance(modalElement);

  const createUpdate = async (e) => {
    e.preventDefault();
    if (loading) return;
    try {
      setLoading(true);
      const payload = { type };
      if (editId) payload.id = editId; // Add ID to payload for update

      const response = await api.post(`/admin/property/type/create/update`, payload, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json'
        }
      });

      if (response.data.status === true) {
        list();
        setType('');
        setEditId(null); // Reset the edit ID
        toast.success(response.data.message);
        modalInstance.hide();
      } else {
        toast.error(response.data.message || response.data.errors[0]?.msg);
        setErrors(response.data.errors || []);
      }
    } catch (error) {
      console.error('Error creating/updating property type:', error);
      setErrors(error.response?.data?.errors || [{ msg: 'Failed to create/update data, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (propertyType) => {
    setType(propertyType.type);
    setEditId(propertyType._id); // Set the ID for editing
    // setModalVisible(true); // Show the modal
    const modalElement = document.getElementById('staticBackdrop');
    const modalInstance = new window.bootstrap.Modal(modalElement);
    modalInstance.show(); // Open the modal
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const response = await api.delete(`/admin/property/type/delete`, {
            data: { id }, // Adjust to ensure id is passed correctly
            headers: {
              'Authorization': `${token}`,
              'Accept': 'application/json',
            },
          });

          if (response.data.status === true) {
            list();
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message || response.data.errors[0]?.msg);
          }
        } catch (error) {
          console.error('Error deleting property type:', error);
          toast.error('Failed to delete data, please try again.');
        } finally {
          setLoading(false);
        }
      } else if (result.isDenied) {
        Swal.fire('Deletion cancelled', '', 'info');
      }
    });
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">

            <button type="button" className="btn btn-primary mb-5" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => { setType(''); setEditId(null); }}>
              Create/Update Property type
            </button>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={createUpdate}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">{editId ? 'Update' : 'Create'} Property Type</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <label htmlFor="type">Type</label>
                        <input
                          type="text"
                          className="form-control"
                          id="type"
                          placeholder="Enter type"
                          name="type"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        />
                        {errors && errors
                          .filter((error) => error.path === 'type')
                          .slice(0, 1)
                          .map((error, index) => (
                            <div key={index} className="text-danger">
                              {error.msg}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Search Property Types"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="form-control mb-3"
                />
              </div>
            </div>
            
            <div className="d-flex justify-content-between">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Property Types</h4>
                    <p className="card-description">List of property types</p>

                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {propertyTypes.length > 0 ? (
                            propertyTypes.map((propertyType) => (
                              <tr key={propertyType._id}>
                                <td>{propertyType.type}</td>
                                <td>{new Date(propertyType.createdAt).toLocaleDateString()}</td>
                                <td>{new Date(propertyType.updatedAt).toLocaleDateString()}</td>
                                <td>
                                  <button className="btn btn-warning btn-sm m-2" onClick={() => handleEdit(propertyType)}>
                                    <BsPencilSquare />
                                  </button>
                                  <button className="btn btn-danger btn-sm m-2" onClick={() => handleDelete(propertyType._id)}>
                                    <BsTrash />
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="9" className="text-center">No property types found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <button className="btn btn-primary" onClick={handlePreviousPage} disabled={page === 1}>
                      Previous
                    </button>
                    <span>Page {page} of {totalPages}</span>
                    <button className="btn btn-primary" onClick={handleNextPage} disabled={page === totalPages}>
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default PropertyTypeIndex;
