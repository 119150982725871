import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUser } from '../Services/Constants'; // Import your getUser function

const PublicRoute = ({ element }) => {
  const user = getUser(); // Get the user from localStorage

  return user ? <Navigate to="/adminDashboard" replace /> : element;
};

export default PublicRoute;
