import logo from "./logo.svg";
import "./App.css";
import Login from "./Components/Admin/Login";
import { BrowserRouter as Router, Routes, Route, Navigate, } from "react-router-dom";
import VerifyOtp from "./Components/Admin/VerifyOtp";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from "./Components/Admin/Dashboard";
import PropertyTypeIndex from "./Components/Admin/PropertyType/PropertyTypeIndex";
import PropertyIndex from "./Components/Admin/Property/PropertyIndex";
import CustomerPropertyIndex from "./Components/Admin/CustomerProperty/CustomerPropertyIndex";
import PaymentIndex from "./Components/Admin/Payment/PaymentIndex";
import PrivateRoute from "./Components/Services/PrivateRoute";
import PublicRoute from "./Components/Services/PublicRoute";
import PropertyIndexView from "./Components/Admin/PropertyIndexView/PropertyIndexView";
import CustomerIndex from "./Components/Admin/Customer/CustomerIndex";

function App() {
  return (
    <Router>
      <div>

        <Routes>
          <Route path="/" element={<Navigate replace to="/adminDashboard" />} />

          {/* Public routes - accessible only if not logged in */}
          <Route path="/adminLogin" element={<PublicRoute element={<Login />} />} />
          <Route path="/adminVerify" element={<PublicRoute element={<VerifyOtp />} />} />

          {/* Private routes - accessible only if logged in */}
          <Route path="/adminDashboard" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/admin-propertytype-index" element={<PrivateRoute element={<PropertyTypeIndex />} />} />
          <Route path="/admin-property-index" element={<PrivateRoute element={<PropertyIndex />} />} />
          <Route path="/admin-customerproperty-index" element={<PrivateRoute element={<CustomerPropertyIndex />} />} />
          <Route path="/admin-payment-verify-index" element={<PrivateRoute element={<PaymentIndex />} />} />
          <Route path="/admin-property-index-view" element={<PrivateRoute element={<PropertyIndexView />} />} />
          <Route path="/customer-index" element={<PrivateRoute element={<CustomerIndex />} />} />


          {/* <Route path="/404" element={<Error />} /> */}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </div>
      <ToastContainer />
    </Router>

  );
}

export default App;
