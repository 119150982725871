import React, { useState, useEffect } from 'react';
import Navbar from '../Layouts/Navbar';
import Sidebar from '../Layouts/Sidebar';
import Footer from '../Layouts/Footer';
import { toast } from 'react-toastify';
import axios from 'axios'; // Ensure axios is imported
import { useLocation } from 'react-router-dom';
import { getUser } from '../../Services/Constants';
import api from '../../Services/Api';

function PropertyIndexView() {
  const location = useLocation();
  const { id } = location.state || {};
  const [state, setState] = useState({ loading: false, data: null, errors: [] });
  const user = JSON.parse(getUser());
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const token = user?.token;
  console.log(state?.data?.data, "-----");

  useEffect(() => {
    if (id) {
      handleView();
      fetchPropertyData()
    } else {
      toast.error('No property ID provided.');
    }
  }, [id]);

  const handleView = async () => {
    try {
      setState(prevState => ({ ...prevState, loading: true }));

      // URL encoded data
      const urlencoded = new URLSearchParams();
      urlencoded.append('id', id);

      // Making the POST request
      const response = await axios.post(
        'http://143.110.253.93:3000/api/admin/property/view',
        urlencoded, // Body of the request
        {
          headers: {
            'Authorization': token,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      // Check response status
      if (response?.data?.status) {
        setState(prevState => ({ ...prevState, data: response?.data, loading: false }));
      } else {
        toast.error(response?.data?.message);
        setState(prevState => ({ ...prevState, loading: false }));
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to retrieve property.');
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };


  const fetchPropertyData = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await api.get(`/admin/property/type/list`, {
        headers: {
          'Authorization': `${token}`,
          'Accept': 'application/json',
        },
      });
      if (response) {
       console.log(response,"---response");
       

      } else {
        setErrors(response.errors || response.message);
      }
    } catch (error) {
      setErrors([{ msg: 'Failed to fetch property types, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic form elements</h4>
                  <form className="forms-sample">
                    <div className="form-group">
                      <label htmlFor="exampleInputName1">Name</label>
                      <input
                        className="form-control"
                        id="exampleInputName1"
                        placeholder="Name"
                        value={state?.data?.data?.name}
                      
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail3">Property Type</label>
                      <input
                        className="form-control"
                        id="eProperty TypexampleInputEmail3"
                        placeholder="Property Type"
                        value={state?.data?.data?.property_type_id?.type}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword4">Rent Amount</label>
                      <input
                        // type="password"
                        className="form-control"
                        id="exampleInputPassword4"
                        placeholder="Rent Amount"
                        value={state?.data?.data?.rent_amount}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleSelectGender">Squarefeet</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputCity1"
                        placeholder="Location"
                        value={state?.data?.data?.squarefeet}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputCity1">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputCity1"
                        placeholder="Location"
                        value={state?.data?.data?.address}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleTextarea1">Textarea</label>
                      <textarea
                        className="form-control"
                        id="exampleTextarea1"
                        // rows={4}
                        defaultValue={""}
                        value={state?.data?.data?.text}
                      />
                    </div>
                    <div class="row"> 
                    <div className="form-group">
                      <label htmlFor="propertyImages">Property Images</label>
                      <div id="propertyImages" className="image-container d-flex flex-wrap">
                        {state?.data?.data?.imagePaths?.length > 0 ? (
                          state.data.data.imagePaths.map((image, index) => (
                            <div key={index} className="property-image mr-3 mb-3 p-3">
                              <img
                                src={image.image_path}
                                alt={`Property Image ${index + 1}`}
                                style={{ width: '500px', height: '400px', objectFit: 'cover', }}
                              />
                            </div>
                          ))
                        ) : (
                          <p>No images available</p>
                        )}
                      </div>
                    </div>
                    </div>
{/*                 
                    <button type="submit" className="btn btn-gradient-primary mr-2">
                      Submit
                    </button>
                    <button className="btn btn-light">Cancel</button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyIndexView;
