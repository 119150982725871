import React, { useState } from 'react';
import api from '../Services/Api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Processing from '../Services/Processing';
import { saveUserLocally } from '../Services/Constants';
import { Helmet } from 'react-helmet';

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await api.post('/admin/login', new URLSearchParams({
        email,
      }));
      console.log('Login successful:', response.data);
      if (response.data.status === true) {
        // saveUserLocally(JSON.stringify(response.data));
        localStorage.setItem('verify', JSON.stringify(response.data));
        toast.success(response.data.message);
        navigate('/adminVerify');
      } else {
        toast.error(response.data.message || response.data.errors[0]);
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrors(error.response?.data?.errors || [{ msg: 'Login failed, please try again.' }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Web site created using create-react-app" />
        {/* <link rel="shortcut icon" href="assets/web/logos/fevicone_rentable.png" type="image/x-icon"> */}
        <link rel="shortcut icon" href="assets/web/logos/fevicone_rentable.png" type="image/x-icon" />
        <link rel="stylesheet" href="assets/web/css/bootstrap.css" />
        <link rel="stylesheet" href="assets/web/css/all.min.css" />
        <link rel="stylesheet" href="assets/web/css/login.css" />
        <title>Rentale Login Page</title>
      </Helmet>
      
      <section className="login-section">
        {loading ? (
          <div className="card loginCard">
            <Processing />
          </div>
        ) : (
          <div className="card loginCard">
            <div className="card-body">
              <div className="page-content text-center">
                <img src="assets/web/logos/rentable_main.png" alt="Main Logo" height="60" width="200"/>
                <h2 className="pageTitle">Welcome to</h2>
                <p className="pagePera">Login to your account.!</p>
              </div>
              <form onSubmit={handleLogin}>
                <div className="form-outline form-white mb-4">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    placeholder="Enter Email"
                    required
                  />
                  {errors && errors
                    .filter((error) => error.path === 'email')
                    .slice(0, 1)
                    .map((error, index) => (
                      <div key={index} className="text-danger">
                        {error.msg}
                      </div>
                    ))}
                </div>
                <div className="remember text-center d-flex justify-content-between flex-wrap gap-3 mt-4">
                  Having trouble in Login?
                </div>
                <button className="btn loginButton" type="submit">
                  Login
                </button>
              </form>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default Login;
